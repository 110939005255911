import React from "react";

export const isBrowser = () => typeof window !== `undefined`;

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {};

export const setUser = (user) => {
  if (isBrowser()) {
    window.localStorage.setItem("gatsbyUser", JSON.stringify(user));
  }
};

export const removeUser = () => {
  if (isBrowser()) {
    window.localStorage.removeItem("gatsbyUser");
  }
};

export const signOut = () => {
  if (isBrowser()) {
    window.localStorage.removeItem("gatsbyUser");
    window.location.href = "/app";
  }
};

export const useStickyState = (defaultValue, key) => {
  const [value, setValue] = React.useState(() => {
    if (isBrowser()) {
      const stickyValue = window.localStorage.getItem(key);
      return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    } else {
      return defaultValue;
    }
  });

  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
