import { navigate } from "gatsby";

import { getUser, removeUser, isBrowser } from "../helpers/LocalStorage";

export const handleForgot = ({ email }) => {
  return true;
};

export const isSignedIn = () => {
  const user = getUser();
  return !!user.accessToken;
};

export const isAdmin = () => {
  const user = getUser();
  return user.staff.member;
};

export const handleLogout = () => {
  removeUser();
  if (isBrowser()) {
    navigate("/");
  }
};
